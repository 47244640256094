<script>
import appConfig from '@src/app.config'
import Layout from '@layouts/main'
import CreateVideo from '@components/video/createvideo'

// import PageHeader from '@components/page-header'

/**
 * Contents component
 */
export default {
  page: {
    title: 'Edite Video',
    meta: [{ name: 'description', content: appConfig.description }],
  },
  components: {
    Layout,
    CreateVideo,

    // PageHeader
  },
  data() {
    return {}
  },
  mounted() {},
  methods: {},
}
</script>

<template>
  <Layout>
    <div class="row page-title align-items-center">
      <div class="col-md-3 col-xl-6">
        <h4 class="mb-1 mt-0"></h4>
      </div>

      <div class="row mt-2" style="height:100%; width:100%;">
        <div class="col-12">
          <div class="card">
            <CreateVideo></CreateVideo>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
